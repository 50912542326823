const violet = "https://ctrees-website.s3.amazonaws.com/staff_photos/vmoon.jpg"
const aleena = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/aashary.jpg'
const adugna = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/amullissa.jpg'
const daniel = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/dmelling.jpg'
const fiona = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/fosborn.jpg'
const fabien = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/fwagner.jpg'
const griffin = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/gcarter.jpg'
const ken = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/kmatsunaga.jpg'
const labien = 'https://ctrees-website.s3.amazonaws.com/staff_photos/lsagang.jpg'
const matt = 'https://ctrees-website.s3.amazonaws.com/staff_photos/mresnick.jpg'
const naomi = 'https://ctrees-website.s3.amazonaws.com/staff_photos/nprovost.jpg'
const ricardo = 'https://ctrees-website.s3.amazonaws.com/staff_photos/rsilva.jpg'
const shivraj = 'https://ctrees-website.s3.amazonaws.com/staff_photos/sgill.jpg'
const shuang = 'https://ctrees-website.s3.amazonaws.com/staff_photos/sli.jpg'
const vaiman = 'https://ctrees-website.s3.amazonaws.com/staff_photos/vshum.jpg'
const sassanImg = 'https://ctrees-website.s3.us-west-2.amazonaws.com/staff_photos/ssaatchi.jpg'
const susana = 'https://ctrees-website.s3.amazonaws.com/staff_photos/stran.jpeg'
const yan = 'https://ctrees-website.s3.amazonaws.com/staff_photos/yyang.jpg'
const zhihua = 'https://ctrees-website.s3.amazonaws.com/staff_photos/zliu.jpg'
const nich = "https://ctrees-website.s3.amazonaws.com/staff_photos/nkwon.jpg"
const stephanie = "https://ctrees-website.s3.amazonaws.com/staff_photos/sgeorge.jpg"
const ray = "https://ctrees-website.s3.amazonaws.com/staff_photos/rsong.jpg"
const phil = "https://ctrees-website.s3.amazonaws.com/staff_photos/pdecola.jpg"
const javiera = "https://ctrees-website.s3.amazonaws.com/staff_photos/jcanales.jpeg"
const stephen = "https://ctrees-website.s3.amazonaws.com/staff_photos/shagen.JPG"
const cynthia = "https://ctrees-website.s3.amazonaws.com/staff_photos/ccreze.jpeg"
const dee = "https://ctrees-website.s3.amazonaws.com/staff_photos/dlawrence.jpg"
const caroline = "https://ctrees-website.s3.amazonaws.com/staff_photos/cdebossart.jpg"
const william = "https://ctrees-website.s3.amazonaws.com/staff_photos/wboyd.jpg"
const liz = "https://ctrees-website.s3.amazonaws.com/staff_photos/landerson.jpg"
const aijing = "https://ctrees-website.s3.amazonaws.com/staff_photos/ali.jpg"
const lorena = "https://ctrees-website.s3.amazonaws.com/staff_photos/lalves.jpg"
const xinbo = "https://ctrees-website.s3.amazonaws.com/staff_photos/xlu.jpg"
const frank = "https://ctrees-website.s3.amazonaws.com/staff_photos/fmwangi.jpg"


export const sassan = [
  {
    name: "Sassan Saatchi",
    img: sassanImg,
    func: "science",
    title: "Co-Founder & CEO",
  },
];

export const staff = {
  us: [
    { name: "Aleena Ashary", img: aleena, func: "technology" },
    { name: "Shivraj Gill", img: shivraj, func: "technology" },
    { name: "Violet Moon", img: violet, func: "technology" },
    { name: "Naomi Provost ", img: naomi, func: "technology" },
    { name: "Matthew Resnick", img: matt, func: "technology" },
    { name: "Liz Anderson", img: liz, func: "technology" },
    { name: "Nicholas Kwon", img: nich, func: "technology" },
    { name: "Frank Mwangi", img: frank, func: "technology" },
    { name: "Xinbo Lu", img: xinbo, func: "technology" },
    { name: "Griffin Carter", img: griffin, func: "science" },
    { name: "Ricardo Dalagnol da Silva", img: ricardo, func: "science" },
    { name: "Phil Decola", title: "Co-founder", img: phil },
    { name: "Shuang Li", img: shuang, func: "science" },
    { name: "Zhihua Liu", img: zhihua, func: "science" },
    { name: "Adugna Mullissa", img: adugna, func: "science" },
    { name: "Stephanie George", img: stephanie, func: "science" },
    { name: "Ken Matsunaga", img: ken, func: "science" },
    { name: "Fiona Osborn", img: fiona, func: "science" },
    { name: "Le Bienfaiteur Sagang", img: labien, func: "science" },
    { name: "Fabien Wagner", img: fabien, func: "science" },
    { name: "Yan Yang", img: yan, func: "science" },
    { name: "Stephen Hagen", img: stephen, func: "science" },
    { name: "Cynthia Creze", img: cynthia, func: "science" },
    { name: "Lorena Alves", img: lorena, func: "science" },
    { name: "Javiera Canales", img: javiera, func: "science" },
    { name: "Daniel Melling", img: daniel, func: "marketing" },
    { name: "Raymond Song", img: ray, func: "marketing" },
    { name: "Aijing Li", img: aijing, func: "marketing" },
    { name: "Vaiman Shum", img: vaiman, func: "operations" },
    { name: "Susana Tran", img: susana, func: "operations" },
  ],
  fr: [
    {
      name: "Philippe Ciais",
      title:
        "Co-founder and Research Director, Institut Pierre-Simon Laplace (IPSL)",
      img: "",
    },
    {
      name: "Jean-Pierre Wigneron",
      title: "Co-founder and Head, INRAE Remote Sensing Lab, Bordeaux",
      img: "",
    },
    { name: "Arthur Calvi", title: "Data Analyst" },
    { name: "Ibrahim Fayad", title: "Research Scientist" },
    { name: "Yu Feng", title: "Research Scientist" },
    { name: "Sami Jouaber", title: "Research Scientist" },
    { name: "Agnès Pellissier-Tanon", title: "Research Scientist" },
    { name: "François Ritter", title: "Research Scientist" },
    { name: "Martin Schwartz ", title: "Data Analyst" },
    { name: "Yidi Xu", title: "Research Scientist" },
  ],
  dn: [
    {
      name: "Martin Brandt",
      title: "Co-founder and Professor of Geography, University of Copenhagen",
      img: "",
    },
    {
      name: "Rasmus Fensholt",
      title: "Co-founder and Professor of Geography, University of Copenhagen",
      img: "",
    },
    { name: "Dimitri Gominski", title: "Research Scientist" },
    { name: "Sizhuo Li", title: "Research Scientist" },
    { name: "Siyu Liu", title: "Research Scientist" },
    { name: "Maurice Mugabowindekwe", title: "Research Scientist" },
    { name: "Florian Reiner", title: "Research Scientist" },
  ],
  bz: [
    {
      name: "Luiz Aragão",
      title:
        "Senior Scientist Head of Earth Observation and Geoinformatics Division, National Institute for Space Research (INPE)",
      img: "",
    },
    { name: "Celso H.L. Silva Junior", title: "Research Scientist" },
    { name: "Liana Anderson", title: "Research Scientist" },
    { name: "Daniel Braga", title: "Research Scientist" },
  ],
};

export const leadership = [
  {
    name: "Sassan Saatchi",
    focus: "Forest Carbon Systems",
    title:
      "Senior Research Scientist, JPL/Caltech; Professor, UCLA Institute of the Environment and Sustainability",
    local: "USA",
  },
  {
    name: "Philippe Ciais",
    focus: "Climate and Carbon",
    title: "Research Director, Institut Pierre-Simon Laplace (IPSL)",
    local: "France",
  },
  {
    name: "Martin Brandt",
    focus: "Data Science and Analytics",
    title: "Professor, Geography, University of Copenhagen",
    local: "Denmark",
  },
  {
    name: "Phil Decola",
    focus: "GHG Inventory",
    title: "Co-chair, WMO Integrated Global GHG Information System",
    local: "USA",
  },
  {
    name: "Rasmus Fensholt",
    focus: "Data Science and Analytics",
    title: "Professor, Geography, University of Copenhagen",
    local: "Denmark ",
  },
  {
    name: "Jean-Pierre Wigneron",
    focus: "Remote Sensing Science",
    title: "Head, INRAE Remote Sensing Lab, Bordeaux",
    local: "France",
  },
];

export const advisors = [
  {
    name: "Susan Cook-Patton",
    title: "Senior Forest Restoration Scientist, The Nature Conservancy",
    local: "USA",
  },

  {
    name: "Jerome Chave",
    title:
      "Laboratoire Évolution & Diversité Biologique, Université Toulouse Paul Sabatier",
    local: "France",
  },

  { name: "KC Cushman", title: "Postdoctoral Fellow, NASA JPL", local: "USA" },

  {
    name: "Alexandre d'Aspremont",
    title:
      "Professor of Computer Science, Department at Ecole Normale Superieure",
    local: "France",
  },

  {
    name: "Grant Domke",
    title: "Forest Carbon Scientist, US Forest Service",
    local: "USA",
  },

  {
    name: "Samuel Favrichon",
    title: "Remote Sensing Scientist, Caltech/NASA JPL",
    local: "USA",
  },

  {
    name: "Giacomo Grassi",
    title:
      "Senior Scientific Officer, Joint Research Centre of the European Commission ",
    local: "Italy",
  },

  {
    name: "Thelma Krug",
    title:
      "Vice Chair, IPCC; Former Co-chair, IPCC Task Force on National Greenhouse Gas Inventories",
    local: "Brazil",
  },

  {
    name: "Ron McRoberts",
    title: "Forest Statistician, Raspberry Ridge Analytics",
    local: "USA",
  },

  {
    name: "Gert-Jan Nabuurs",
    title:
      "Professor European Forest Resources, Wageningen University and Research; Lead Scientist European Forest Resources, Wageningen Environmental Research (Alterra)",
    local: "The Netherlands",
  },

  {
    name: "Herman Shugart",
    title: "Professor Emeritus of Forest Ecology, University of Virginia",
    local: "USA",
  },

  {
    name: "Miles Silman",
    title:
      "Professor of Biology and Director, Center for Energy, Environment & Sustainability, Wake Forest University",
    local: "USA",
  },

  {
    name: "Compton Tucker",
    title: "Senior Scientist, NASA Goddard Space Flight Center",
    local: "USA",
  },

  {
    name: "Lee White",
    title: "Minister for Forests, Sea, the Environment and Climate Change",
    local: "Gabon",
  },
];

export const bodir = [
  {
    name: "Dee Lawrence",
    title: "Director and co-founder, High Tide Foundation",
    local: "USA",
    img: dee
  },
  {
    name: "Caroline de Bossart",
    title:
      "Director, Neglected Climate Opportunities, Grantham Foundation for the Protection of the Environment",
    local: "USA",
    img: caroline
  },
  {
    name: "William Boyd",
    title:
      "Professor of Law, UCLA School of Law; Professor, UCLA Institute of the Environment and Sustainability; Project Lead, Governors' Climate and Forests Task Force",
    local: "USA",
    img: william
  },
];
